<template>
  <div id="defaultModal" aria-hidden="true" class="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full bg-gray-500 bg-opacity-50 flex justify-center items-center"
  >
    <div class="relative p-4 w-full max-w-2xl h-full md:h-auto">
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <!-- Modal header -->
        <div class="flex justify-between items-start p-5 rounded-t border-b dark:border-gray-600">
          <h3 class="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white">
            Add Check Query
          </h3>
          <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" @click="toggleFlag">
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
          </button>
        </div>
        <!-- Modal body -->
        <div class="p-6 space-y-6">
          <select class="form-select appearance-none
            block
            w-full
            px-3
            py-1.5
            text-base
            font-normal
            text-gray-700
            bg-white bg-clip-padding bg-no-repeat
            border border-solid border-gray-300
            rounded
            transition
            ease-in-out
            m-0
            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" aria-label="Default site select"
            v-model="selected">
              <option :value="site.id" v-for="(site, cksi) in sites" :key="'cksi-' + cksi" >{{site.name}}</option>
            </select>
            <textarea
              class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              rows="3"
              placeholder="Put queries"
              v-model="queryinput"
            ></textarea>
        </div>
        <!-- Modal footer -->
        <div class="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
          <button data-modal-toggle="defaultModal" type="button" class="text-white bg-blue-300 not-disabled:bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          :disabled="queryinput === '' || selected === null"
          @click="addQuery"
          >Add</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "../../db";
import { liveQuery } from "dexie";
import { useObservable } from "@vueuse/rxjs";


export default {
  name:'CheckerAdd',
  data() {
    return {
      selected: null,
      queryinput: ''
    }
  },
  inject: ['toggleFlag'],
  props: ['selectedVal'],
  setup() {
    return {
      db,
      sites: useObservable(
        liveQuery(async () => {
          return await db.sites
            .orderBy('name')
            .toArray();
        })
      )
    };
  },
  mounted(){
    if(this.selectedVal){
      this.selected = this.selectedVal;
    }
  },
  methods:{
    async addQuery(){
      const keywords = this.queryinput.split('\n');

      const result = [];

      for(let k in keywords){
        if(keywords[k] !== ''){
          const tmp  = {};
          tmp.sid = this.selected;
          tmp.w = keywords[k].replace(/\u3000/g, " ");
          result.push(tmp);
        }
      }
      // console.log(result);
      await db.queries.bulkPut(result);
      this.selected = null;
      this.queryinput = '';
      this.toggleFlag();
      this.$router.go({path: this.$router.currentRoute.path, force: true})
    }
  }
}

</script>
